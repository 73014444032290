<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div> -->

  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card-header class="mb-3">
      <b-row>
        <b-col> <i class="icon-menu mr-1"></i>Mukta Account Report</b-col>
      </b-row>
    </b-card-header>
    <b-card>
      <div class="row">
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">From Date :</label>
            <b-form-input
              type="date"
              v-model="detailAccountsData.FromDate"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group>
            <label for="to-date">To Date :</label>
            <b-form-input
              type="date"
              v-model="detailAccountsData.ToDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">Select Cinema Name</label>
            <b-form-select
              v-model="detailAccountsData.selectedCinema"
              id="cinemas"
              :plain="true"
              text-field="CinemaName"
              value-field="CinemaID"
              :options="$store.getters['common/getAllCinemas']"
              @change="getMovie"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Cinema --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="movie_name">Movie :</label>
            <b-form-select
              required
              v-model="detailAccountsData.selectedMovie"
              id="movie_name"
              :plain="true"
              text-field="Title"
              value-field="ID"
              :options="MovieList"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Movie --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="row">
        <b-col sm="12" class="text-center">
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="getMuktaAccountsReport"
            >Search</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click.prevent="resetForm"
            >Reset</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="ExportMuktaAccountsExcel"
            >Export to Excel</b-button
          >
        </b-col>
      </div>
    </b-card>
    <hr />
    <b-card>
           <div style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;" v-if="isLoader"><CircleLoader  />
    </div>
      <v-client-table
        :data="data1"
        :columns="columns1"
        :options="options1"
      ></v-client-table>
      <div class="table_class">
        <table class="table table-bordered w-100">
          <thead style="color: white">
            <tr>
              <th>SN.</th>
              <th>Cinema Name</th>
              <th>Movie</th>
              <th>Transaction Date</th>
              <th>Movie Date</th>
              <th>Total Transactions</th>
              <th>Payment Type</th>
              <th>Total Tickets</th>
              <th>Paid Amount</th>
              <th colspan="13">Paid to Mukta</th>
              <th colspan="9">Paid to Telebu</th>
              <th>Paid to Payment Gateway</th>
              <th>Failed Transactions</th>
              <th>Failed Tickets</th>
              <th>Ticket Transaction Amount</th>
            </tr>
            <tr>
              <th colspan="9"></th>
              <th>Basic Ticket Price(BTP)</th>
              <th>VAT on BTP</th>
              <th>Bank Charges(BC) on BTP</th>
              <th>VAT on BC on BTP</th>
              <!-- Mukta -->
              <th>Service Charges(SC)</th>
              <th>VAT on SC</th>
              <th>Bank Charges(BC) on SC</th>
              <th>VAT on BC on SC</th>

              <th>SCB Convenience Fee</th>
              <th>VAT(10%) on SCB Convenience Fee</th>
              
              <th>SCB Convenience fee (Wanasa Time share)</th>
              <th>VAT(10%) on SCB Convenience Fee (Wanasa Time share)</th>
              <th>Transferable to Mukta</th>
              
              <th>Service Charges(SC)</th>
              <th>VAT on SC</th>
              <th>Bank Charges(BC) on SC</th>
              <th>VAT on BC on SC</th>
              <th>SCB Convenience Fee</th>
              <th>VAT(10%) on SCB Convenience Fee</th>
              <th>BC ON SCB CONVENIENCE FEE</th>
              <th>VAT ON BC ON SCB CONVENIENCE FEE</th>

              
              <th>Total Amount</th>
              <!-- Mukta -->
              <th colspan="4"></th>
            </tr>
          </thead>

          <tbody v-if="displayedRecords.length > 0">
            <tr v-for="(item, index) in displayedRecords" :key="index">
              <td>{{ item.Sr }}</td>
              <td>{{ item.CinemaName }}</td>
              <td>{{ item.MovieName }}</td>
              <td>{{ item.TransactionDate }}</td>
              <td>{{ item.ShowDate }}</td>
              <td>{{ item.TransactionCount }}{{ getUpdate }}</td>
              <!-- <td>{{ ToT_TransactionCount(index) }}</td> -->
              <td>{{ item.PaymentType }}</td>
              <td>{{ item.NoofTicket }}</td>
              <td>{{ item.PaidAmount }}</td>
              <td>{{ item.BaseTicketPrice }}</td>
              <td>{{ item.VATONBTP }}</td>
              <td>{{ item.BCONBTP }}</td>
              <td>{{ item.VATONBCONBTP }}</td>

              <td>{{ item.service_charges_mukta }}</td>
              <td>{{ item.vat_service_charges_mukta }}</td>
              <td>{{ item.bc_service_charges_mukta }}</td>
              <td>{{ item.vat_bc_service_charges_mukta }}</td>

              <td>{{ item.SCBConveniencefee }}</td>
              <td>{{ item.VATONSCBConveniencefee }}</td>
                            <td>{{ item.Telebu_SCBConveniencefee }}</td>
              <td>{{ item.Telebu_VATONSCBConveniencefee }}</td>
              <td>{{ item.TotalAmount }}</td>
              <td>{{ item.service_charge_telebu }}</td>
              <td>{{ item.vat_service_charge_telebu }}</td>
              <td>{{ item.bc_service_charges_telebu }}</td>
              <td>{{ item.vat_bc_service_charges_telebu }}</td>
              <td>{{ item.Telebu_SCBConveniencefee }}</td>
              <td>{{ item.Telebu_VATONSCBConveniencefee }}</td>

              <td>{{ item.bconscb_telebu }}</td>
              <td>{{ item.vatonbconscb_telebu }}</td>

              <td>{{ item.Telebu_TotalAmount }}</td>
              <td>{{ item.PaidToPG }}</td>
              <td>{{ item.FailedTransactions }}</td>
              <td>{{ item.FailedTickets }}</td>
              <td>{{ item.FailedAmount }}</td>
            </tr>
            <tr>
              <td colspan="5"><b>Total</b></td>
              <td>{{ TotalTransactionCount }}</td>
              <td></td>
              <td>{{ TotalNoofTicket }}</td>
              <td>{{ TotalPaidAmount.toFixed(3) }}</td>
              <td>{{ TotalBTP.toFixed(3) }}</td>
              <td>{{ TotalVATONBTP.toFixed(3) }}</td>
              <td>{{ TotalBCONBTP.toFixed(3) }}</td>
              <td>{{ TotalVATONBCONBTP.toFixed(3) }}</td>

              <td>{{ Totalservice_charges_mukta.toFixed(3) }}</td>
              <td>{{ Totalvat_service_charges_mukta.toFixed(3) }}</td>
              <td>{{ Totalbc_service_charges_mukta.toFixed(3) }}</td>
              <td>{{ Totalvat_bc_service_charges_mukta.toFixed(3) }}</td>

              <td>{{ TotalSCBConveniencefee.toFixed(3) }}</td>
              <td>{{ TotalVATONSCBConveniencefee.toFixed(3) }}</td>
                            <td>{{ Totalbconscb_telebu.toFixed(3) }}</td>
              <td>{{ Totalvatonbconscb_telebu.toFixed(3) }}</td>
              <td>{{ TotalTotalAmount.toFixed(3) }}</td>

              <td>{{ Totalservice_charge_telebu.toFixed(3) }}</td>
              <td>{{ Totalvat_service_charge_telebu.toFixed(3) }}</td>
              <td>{{ Totalbc_service_charges_telebu.toFixed(3) }}</td>
              <td>{{ Totalvat_bc_service_charges_telebu.toFixed(3) }}</td>

              <td>{{ TotalTelebu_SCBConveniencefee.toFixed(3) }}</td>
              <td>{{ TotalTelebu_VATONSCBConveniencefee.toFixed(3) }}</td>

              <td>{{ Totalbconscb_telebu.toFixed(3) }}</td>
              <td>{{ Totalvatonbconscb_telebu.toFixed(3) }}</td>

              <td>{{ TotalTelebu_TotalAmount.toFixed(3) }}</td>
              <td>{{ TotalPaidToPG.toFixed(3) }}</td>
              <td>{{ TotalFailedTransactions.toFixed(3) }}</td>
              <td>{{ TotalFailedTickets.toFixed(3) }}</td>
              <td>{{ TotalFailedAmount.toFixed(3) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12"><center>Data Not Found!</center></td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :records="records.length"
        v-model="page"
        :per-page="perPage"
        @paginate="callback"
      >
      </pagination>
     
      <hr />
    </b-card>
  </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../SchedulesDetails/Circle.vue";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";
import Pagination from "vue-pagination-2";
import vSelect from "vue-select";

export default {
  name: "MuktaAccountsReport",
  components: {
    Event,
    cSwitch,
    vSelect,
    Pagination,
    // Loading,
    CircleLoader
  },
  watch: {},
  data: function () {
    return {
      isLoader: false,
      TotalTransactionCount: 0,
      TotalNoofTicket: 0,
      TotalPaidAmount: 0,
      TotalBTP: 0,
      TotalVATONBTP: 0,
      TotalBCONBTP: 0,
      TotalVATONBCONBTP: 0,
      TotalSCBConveniencefee: 0,
      TotalVATONSCBConveniencefee: 0,
      TotalTotalAmount: 0,

      Totalservice_charges_mukta: 0,
      Totalvat_service_charges_mukta: 0,
      Totalbc_service_charges_mukta: 0,
      Totalvat_bc_service_charges_mukta: 0,

      Totalservice_charge_telebu: 0,
      Totalvat_service_charge_telebu: 0,
      Totalbc_service_charges_telebu: 0,
      Totalvat_bc_service_charges_telebu: 0,

      TotalTelebu_SCBConveniencefee: 0,
      TotalTelebu_VATONSCBConveniencefee: 0,

      Totalbconscb_telebu: 0,
      Totalvatonbconscb_telebu: 0,

      TotalTelebu_TotalAmount: 0,
      TotalPaidToPG: 0,
      TotalFailedTransactions: 0,
      TotalFailedTickets: 0,
      TotalFailedAmount: 0,
      page: 1,
      perPage: 10,
      records: [],
      counter: 21,
      MovieList: [],
      detailAccountsData: {
        FromDate: "",
        ToDate: "",
        selectedCinema: 0,
        selectedPaymentMode: "",
        selectedMovie: 0,
      },
      paymentList: ["All", "Debit Card", "Credit Card", "SCB"],
      columns: [
        "SN",
        "CinemaName",
        "MovieName",
        "TransactionDate",
        "ShowDate",
        "TransactionCount",
        "PaymentType",
        "NoofTicket",
        "PaidAmount",
        "BaseTicketPrice",
        "VATONBTP",
        "BCONBTP",
        "VATONBCONBTP",

        "service_charges_mukta",
        "vat_service_charges_mukta",
        "bc_service_charges_mukta",
        "vat_bc_service_charges_mukta",

        "SCBConveniencefee",
        "VATONSCBConveniencefee",
        "Telebu_SCBConveniencefee",
        "Telebu_VATONSCBConveniencefee",
        "TotalAmount",

        "service_charge_telebu",
        "vat_service_charge_telebu",
        "bc_service_charges_telebu",
        "vat_bc_service_charges_telebu",

        "Telebu_SCBConveniencefee",
        "Telebu_VATONSCBConveniencefee",

        "bconscb_telebu",
        "vatonbconscb_telebu",

        "Telebu_TotalAmount",
        "PaidToPG",
        "FailedTransactions",
        "FailedTickets",
        "FailedAmount",
      ],
      data: [],
      finalArr: [],
      ExcelArr: [],
      TotalDetails: [],
      options: {
        headings: {
          Sr: "SN",
          CinemaName: "Cinema Name",
          MovieName: "Movie",
          TransactionDate: "Transaction Date",
          ShowDate: "Movie Date",
          TransactionCount: "Total Transactions",
          PaymentType: "Payment Type",
          NoofTicket: "Total Tickets",
          PaidAmount: "Paid Amount",
          BaseTicketPrice: "Basic Ticket Price (BTP)",
          VATONBTP: "VAT ON BTP",
          BCONBTP: "Bank Charges(BC) on BTP",
          VATONBCONBTP: "VAT on BC on BTP",

          service_charges_mukta: "Service Charges Mukta",
          vat_service_charges_mukta: "VAT on SC Mukta",
          bc_service_charges_mukta: "Bank Charges(BC) on SC Mukta",
          vat_bc_service_charges_mukta: "VAT on BC on SC Mukta",

          SCBConveniencefee: "SCB Convenience Fee",
          VATONSCBConveniencefee: "VAT(10%) on SCB Convenience Fee",
          Telebu_SCBConveniencefee: "SCB Convenience Fee (Wanasa Time share)",
          Telebu_VATONSCBConveniencefee: "VAT(10%) on SCB Convenience Fee (Wanasa Time share)",
          TotalAmount: "Transferable to Mukta",

          service_charge_telebu: "Service Charges Telebu",
          vat_service_charge_telebu: "VAT on SC Telebu",
          bc_service_charges_telebu: "Bank Charges(BC) on SC Telebu",
          vat_bc_service_charges_telebu: "VAT on BC on SC Telebu",

          Telebu_SCBConveniencefee: "Wanasa Time SCB Convenience Fee",
          Telebu_VATONSCBConveniencefee:
            "Wanasa Time VAT(10%) on SCB Convenience Fee",

          bconscb_telebu: "BC ON SCB CONVENIENCE FEE",
          vatonbconscb_telebu: "VAT ON BC ON SCB CONVENIENCE FEE",

          Telebu_TotalAmount: "Wanasa Time Total Amount",
          PaidToPG: "Paid to Payment Gateway",
          FailedTransactions: "Failed Transactions",
          FailedTickets: "Failed Tickets",
          FailedAmount: "Ticket Transaction Amount",
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      columns1: [
        "TotalAmount",
        "SCBTickets",
        "NormalTickets",
        "TotalTickets",
        "MuktaBankCharges",
        "MuktaAmount",
        "PGAmount",
        "TelebuBankCharges",
        "TelebuAmount",
      ],
      data1: [],
      options1: {
        headings: {
          TotalAmount: "Total Amount (BHD)",
          SCBTickets: "SCB Tickets",
          NormalTickets: "Normal Tickets",
          TotalTickets: "Total Tickets",
          MuktaBankCharges: "Mukta Bank Charges",
          MuktaAmount: "Mukta Amount(BHD)",
          PGAmount: "Payment Gateway Amount(BHD)",
          TelebuBankCharges: "Wanasa Time Bank Charges",
          TelebuAmount: "Wanasa Time Amount(BHD)",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  beforeMount() {
    let payload = {};
    // alert(this.secureStore.get("TTURO"));
    if (this.secureStore.get("TTURO") == "Admin" || this.secureStore.get("TTURO") =="Finance") {
      payload = {
        OrgID: 7,
      };
    }
    this.$store.dispatch("common/setAllCinemas", payload);
    // this.$store.dispatch("common/setAllCinemas");
    this.getFromToDate();
    this.getMuktaAccountsReport();
  },
  mounted() {},
  methods: {
    getMovie() {
      if (this.detailAccountsData.selectedCinema) {
        let payload = {
          CinemaID: this.detailAccountsData.selectedCinema,
        };
        this.MovieList = [];
        AccountReportService.getMoviesByScreenID(payload)
          .then((response) => {
            const { data } = response;
            if (data.MovieList.length > 0) {
              this.MovieList = data.MovieList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    callback: function (page) {
      this.TotalTransactionCount = 0;
      this.TotalNoofTicket = 0;
      this.TotalPaidAmount = 0;
      this.TotalBTP = 0;
      this.TotalVATONBTP = 0;
      this.TotalBCONBTP = 0;
      this.TotalVATONBCONBTP = 0;
      this.TotalSCBConveniencefee = 0;
      this.TotalVATONSCBConveniencefee = 0;
      this.TotalTotalAmount = 0;

      this.Totalservice_charges_mukta = 0;
      this.Totalvat_service_charges_mukta = 0;
      this.Totalbc_service_charges_mukta = 0;
      this.Totalvat_bc_service_charges_mukta = 0;

      this.Totalservice_charge_telebu = 0;
      this.Totalvat_service_charge_telebu = 0;
      this.Totalbc_service_charges_telebu = 0;
      this.Totalvat_bc_service_charges_telebu = 0;

      this.TotalTelebu_SCBConveniencefee = 0;
      this.TotalTelebu_VATONSCBConveniencefee = 0;

      this.Totalbconscb_telebu = 0;
      this.Totalvatonbconscb_telebu = 0;

      this.TotalTelebu_TotalAmount = 0;
      this.TotalPaidToPG = 0;
      this.TotalFailedTransactions = 0;
      this.TotalFailedTickets = 0;
      this.TotalFailedAmount = 0;
    },
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      // firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.FromDate = today;
      this.detailAccountsData.FromDate = firstDay;
      this.detailAccountsData.ToDate = firstDay;
      // this.detailAccountsData.ToDate = lastDay;
    },
    ExportMuktaAccountsExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Mukta_Accounts");
        let ExcelDate = new Date().toDateString();
        if (this.detailAccountsData.FromDate) {
          ExcelDate = new Date(this.detailAccountsData.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_Mukta_Accounts_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getMuktaAccountsReport() {
      this.data = [];
      this.data1 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;
      let payload = {
        FromDate: this.detailAccountsData.FromDate,
        ToDate: this.detailAccountsData.ToDate,
        CinemaID: this.detailAccountsData.selectedCinema,
        PaymentMode: this.detailAccountsData.selectedPaymentMode,
        report_type: "internal",
        MovieID: this.detailAccountsData.selectedMovie,
      };

      AccountReportService.getMuktaAccountsReport(payload)
        .then((response) => {
          const { data } = response;
          if (
            data.Status &&
            (data.InternalReportsDetails.length > 0 ||
              data.TotalDetails.length > 0)
          ) {
            this.data = data.InternalReportsDetails;
            this.data1 = data.TotalDetails;
            this.records = [];
            if (this.data.length > 0) {
              this.data.forEach((element) => {
                this.records.push(element);
              });
              let finalArr = this.finalArr;
              let NewArr = this.data.map((x) => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Cinema Name": x["CinemaName"],
                  "Movie Name": x["MovieName"],
                  "Transaction Date": x["TransactionDate"],
                  "Movie Date": x["ShowDate"],
                  "Total Transactions": x["TransactionCount"],
                  "Payment Type": x["PaymentType"],
                  "Total Tickets": x["NoofTicket"],
                  "Paid Amount": x["PaidAmount"],
                  "Basic Ticket Price (BTP)": x["BaseTicketPrice"],
                  "VAT ON BTP": x["VATONBTP"],
                  "Bank Charges(BC) on BTP": x["BCONBTP"],
                  "VAT on BC on BTP": x["VATONBCONBTP"],

                  "Service Charges Mukta": x["service_charges_mukta"],
                  "VAT on SC  Mukta": x["vat_service_charges_mukta"],
                  "Bank Charges(BC) on SC  Mukta": x["bc_service_charges_mukta"],
                  "VAT on BC on SC  Mukta": x["vat_bc_service_charges_mukta"],

                  "SCB Convenience Fee": x["SCBConveniencefee"],
                  "VAT(10%) on SCB Convenience Fee":
                    x["VATONSCBConveniencefee"], 

                     "SCB Convenience Fee (Wanasa Time share)": x["Telebu_SCBConveniencefee"],
                  "VAT(10%) on SCB Convenience Fee (Wanasa Time share)":
                    x["Telebu_VATONSCBConveniencefee"], 
                  "Transferable to Mukta": x["TotalAmount"],

                  "Service Charges Telebu": x["service_charge_telebu"],
                  "VAT on SC Telebu": x["vat_service_charge_telebu"],
                  "Bank Charges(BC) on SC Telebu": x["bc_service_charges_telebu"],
                  "VAT on BC on SC Telebu": x["vat_bc_service_charges_telebu"],

                  "Wanasa Time SCB Convenience Fee": x["Telebu_SCBConveniencefee"],
                  "Wanasa Time VAT(10%) on SCB Convenience Fee":
                    x["Telebu_VATONSCBConveniencefee"],
                    
                  "BC ON SCB CONVENIENCE FEE": x["bconscb_telebu"],
                  "VAT ON BC ON SCB CONVENIENCE FEE": x["vatonbconscb_telebu"],

                  "Wanasa Time Total Amount": x["Telebu_TotalAmount"],
                  "Paid to Payment Gateway": x["PaidToPG"],
                  "Failed Transactions": x["FailedTransactions"],
                  "Failed Tickets": x["FailedTickets"],
                  "Ticket Transaction Amount": x["FailedAmount"],
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch((error) => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm() {
      Object.keys(this.detailAccountsData).forEach(
        (key) => (this.detailAccountsData[key] = "")
      );
      this.getFromToDate();
      this.detailAccountsData["selectedCinema"] = 0;
      this.detailAccountsData["selectedPaymentMode"] = "";
      this.detailAccountsData["selectedMovie"] = 0;
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  computed: {
    displayedRecords() {
      this.TotalTransactionCount = 0;
      this.TotalNoofTicket = 0;
      this.TotalPaidAmount = 0;
      this.TotalBTP = 0;
      this.TotalVATONBTP = 0;
      this.TotalBCONBTP = 0;
      this.TotalVATONBCONBTP = 0;
      this.TotalSCBConveniencefee = 0;
      this.TotalVATONSCBConveniencefee = 0;
      this.TotalTotalAmount = 0;

      this.Totalservice_charges_mukta = 0;
      this.Totalvat_service_charges_mukta = 0;
      this.Totalbc_service_charges_mukta = 0;
      this.Totalvat_bc_service_charges_mukta = 0;

      this.Totalservice_charge_telebu = 0;
      this.Totalvat_service_charge_telebu = 0;
      this.Totalbc_service_charges_telebu = 0;
      this.Totalvat_bc_service_charges_telebu = 0;

      this.TotalTelebu_SCBConveniencefee = 0;
      this.TotalTelebu_VATONSCBConveniencefee = 0;

      this.Totalbconscb_telebu = 0;
      this.Totalvatonbconscb_telebu = 0;

      this.TotalTelebu_TotalAmount = 0;
      this.TotalPaidToPG = 0;
      this.TotalFailedTransactions = 0;
      this.TotalFailedTickets = 0;
      this.TotalFailedAmount = 0;
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      // return this.records.slice(startIndex, endIndex);
      // console.log("this.records=>", this.records);
      return this.records.slice(startIndex, endIndex);
      //  this.records.slice(startIndex, endIndex);

      var newArray = [];

      this.records.forEach((value) => {
        var exists = false;
        newArray.forEach((val2) => {
          if (value.OrderID == val2.OrderID) {
            exists = true;
          }
        });
        if (exists == false && value.OrderID != "") {
          newArray.push(value);
        }
      });
      this.records = newArray;

      console.log("this.records=>", this.records);

      return newArray;
    },
    getUpdate() {
      // this.callback();
      this.displayedRecords.forEach((element) => {
        this.TotalTransactionCount =
          parseInt(this.TotalTransactionCount) +
          parseInt(element.TransactionCount);
        this.TotalNoofTicket =
          parseInt(this.TotalNoofTicket) + parseInt(element.NoofTicket);
        this.TotalPaidAmount =
          parseFloat(this.TotalPaidAmount) + parseFloat(element.PaidAmount);
        this.TotalBTP = parseFloat(this.TotalBTP) + parseFloat(element.BaseTicketPrice);
        this.TotalVATONBTP =
          parseFloat(this.TotalVATONBTP) + parseFloat(element.VATONBTP);
        this.TotalBCONBTP =
          parseFloat(this.TotalBCONBTP) + parseFloat(element.BCONBTP);
        this.TotalVATONBCONBTP =
          parseFloat(this.TotalVATONBCONBTP) + parseFloat(element.VATONBCONBTP);
        this.TotalSCBConveniencefee =
          parseFloat(this.TotalSCBConveniencefee) +
          parseFloat(element.SCBConveniencefee);
        this.TotalVATONSCBConveniencefee =
          parseFloat(this.TotalVATONSCBConveniencefee) +
          parseFloat(element.VATONSCBConveniencefee);
        this.TotalTotalAmount =
          parseFloat(this.TotalTotalAmount) + parseFloat(element.TotalAmount);

        this.Totalservice_charges_mukta =
          parseFloat(this.Totalservice_charges_mukta) +
          parseFloat(element.service_charges_mukta);
        this.Totalvat_service_charges_mukta =
          parseFloat(this.Totalvat_service_charges_mukta) +
          parseFloat(element.vat_service_charges_mukta);
        this.Totalbc_service_charges_mukta =
          parseFloat(this.Totalbc_service_charges_mukta) +
          parseFloat(element.bc_service_charges_mukta);
        this.Totalvat_bc_service_charges_mukta =
          parseFloat(this.Totalvat_bc_service_charges_mukta) +
          parseFloat(element.vat_bc_service_charges_mukta);

        this.Totalservice_charge_telebu =
          parseFloat(this.Totalservice_charge_telebu) +
          parseFloat(element.service_charge_telebu);
        this.Totalvat_service_charge_telebu =
          parseFloat(this.Totalvat_service_charge_telebu) +
          parseFloat(element.vat_service_charge_telebu);
        this.Totalbc_service_charges_telebu =
          parseFloat(this.Totalbc_service_charges_telebu) +
          parseFloat(element.bc_service_charges_telebu);
        this.Totalvat_bc_service_charges_telebu =
          parseFloat(this.Totalvat_bc_service_charges_telebu) +
          parseFloat(element.vat_bc_service_charges_telebu);

        this.TotalTelebu_SCBConveniencefee =
          parseFloat(this.TotalTelebu_SCBConveniencefee) +
          parseFloat(element.Telebu_SCBConveniencefee);
        this.TotalTelebu_VATONSCBConveniencefee =
          parseFloat(this.TotalTelebu_VATONSCBConveniencefee) +
          parseFloat(element.Telebu_VATONSCBConveniencefee);

        this.Totalbconscb_telebu =
          parseFloat(this.Totalbconscb_telebu) +
          parseFloat(element.bconscb_telebu);
        this.Totalvatonbconscb_telebu =
          parseFloat(this.Totalvatonbconscb_telebu) +
          parseFloat(element.vatonbconscb_telebu);

        this.TotalTelebu_TotalAmount =
          parseFloat(this.TotalTelebu_TotalAmount) +
          parseFloat(element.Telebu_TotalAmount);
        this.TotalPaidToPG =
          parseFloat(this.TotalPaidToPG) + parseFloat(element.PaidToPG);
        this.TotalFailedTransactions =
          parseFloat(this.TotalFailedTransactions) +
          parseFloat(element.FailedTransactions);
        this.TotalFailedTickets =
          parseFloat(this.TotalFailedTickets) +
          parseFloat(element.FailedTickets);
        this.TotalFailedAmount =
          parseFloat(this.TotalFailedAmount) + parseFloat(element.FailedAmount);
      });
    },
  },
  created() {
    this.records = [];
    if (this.data2.length > 0) {
      this.data2.forEach((element) => {
        this.records.push(element);
      });
    }
  },
};
</script>

<style>
label {
  font-weight: 600;
}
.table_class {
  overflow: auto;
}
td {
  padding: 0px;
}
.table-bordered thead th {
  border: 1px solid white;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pagination-row {
  padding: 5px 0;
}

.my-custom-scrollbar {
  position: relative;
  height: 850px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
